.nav-bottom-main {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-top: 2.2rem;
	padding-bottom: 2.2rem;
}
.nav-bottom-logo {
	flex: 0.5;
	align-items: center;
    display: flex;
  .logo-name {
    background-clip: text;
    background: linear-gradient(95.89deg, #fb2e86 0%, #403c56 117%);
    font-weight: 700;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
	font-size: 2rem;
  }
}

.nav-bottom {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex: 1.5;
	height: 4.1rem;
}
.nav-bottom > ul {
	display: flex;
	gap: 30px;
}
.nav-bottom > ul > li {
	list-style: none;
	font-size: 1.6rem;
	font-family: lato;
	font-style: normal;
	font-weight: normal;
}
.nav-bottom-input {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex: 1;
}
.nav-bottom-inputmain {
	border: 2px solid #e7e6ef;
	height: 4rem;
	width: 30.7rem;
	display: flex;
	justify-content: flex-end;
}
.nav-bottom-input > .nav-bottom-inputmain > input {
	border: none;
	width: 100%;
}
.nav-bottom-input > .nav-bottom-inputmain > button {
	border: none;
	height: 100%;
	width: 5.1rem;
	background-color: var(--primary-button-color);
}

.whatsapp-icon {
	width: 30px;
}

.nav-bottom-main {
	a {
		all: unset;
	}
	.nav-bottom {
		ul {
			li {
				display: flex;
				align-items: center;
        a {
          cursor: pointer;
        }
				.link-with-icon {
					display: flex;
					align-items: center;
					img {
						margin-left: 10px;
					}
				}
			}
		}
	}
}

.a-unset {
  all: unset;
}
.featured-main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
.shopex-card-main{
    display: flex;
    flex-wrap: wrap;
}
  
.shopex-title {
    font-family: Josefin Sans;
    font-size: 4.2rem;
    line-height: 4.9rem;
    color: #1a0b5b;
    font-weight: 600;
    padding-bottom: 5.5rem;
  }
  .shopex-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 2rem ;
    height: 32rem;
    width: 27rem;
    box-shadow: 0px 8px 40px rgba(49, 32, 138, 0.05);
  }
  .shopex-card > img {
    width: 6.5rem;
    height: 6.5rem;
    margin-bottom: 2.7rem;
  }
  .shopex-card-title {
    font-family: Josefin Sans;
    font-size: 2.2rem;
    color: #151875;
    margin-bottom: 2rem;
  }
  .shopex-card-content {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    text-align: center;
    color: rgba(26, 11, 91, 0.3);
    padding: 0 10px;
  }
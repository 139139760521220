* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  font-size: 10px;
  --primary-color: #fff;
  --header-main-color: #7e33e0;
  --primary-button-color: #fb2e86;
}

html,
body {
  font-family: sans-serif;
  background: white;
}

header {
  background-color: var(--header-main-color);
}

.container {
  max-width: 124rem;
  margin: 0 auto;
}

.our-services{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.our-services-main{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.our-services-title-start{
    font-family: Josefin Sans;
    font-size: 2.2rem;
    line-height: 4.9rem;
    color: #1a0b5b;
    font-weight: 600;
    margin-top: 12.9rem;
    
}
.our-services-card-main{
    display: flex;
}
.our-services-title{
    font-family: Josefin Sans;
    font-size: 4.2rem;
    line-height: 4.9rem;
    color: #1a0b5b;
    font-weight: 600;
    margin-bottom: 4.8rem;
}
.our-services-card{

    min-width: 40rem;
    max-width: 40rem;
    background: #f7f7f7;
    padding: 4rem;
    margin: 2rem
}
.our-services-card-icon{
    height: 15rem;
    width: auto;
    margin-bottom: 5rem;
}
.our-services-card-inner{
    display: flex;
    flex-direction: column;
}
.our-services-card-title{
    font-family: Josefin Sans;
    font-size: 1.6rem;
    color: #151875;
}
.our-services-card-content{
    font-size: 1.6rem;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    color: #8a8fb9;
    margin-top: 2rem;
}
.our-services-card-readMore{
    display: flex;
    align-items: center;
    width: 16.3rem;
    height: 5rem;
    font-family: Josefin Sans;
    font-size: 1.7rem;
    color: #151875;
    margin-top: 2rem
    
}
.our-services-card-arrow{
    height: 3rem;
    width: 3rem;
    margin-left: 2rem;
}
@media only screen and (max-width: 600px) {
	.nav-bottom-main {
		justify-content: center;
		.nav-bottom-logo {
			justify-content: center;
		}
		.nav-bottom {
			justify-content: center;
		}
	}
	.landing-master {
		.landing-main {
			padding: 20px;
			.landing-sub {
				padding: 20px 0;
				font-size: 3rem;
				line-height: 3.2rem;
			}
		}
	}
	.landing-right-img {
		display: none;
	}
	.our-services {
		.our-services-main {
			.our-services-title-start {
				margin-top: 20px;
			}
			.our-services-title {
				font-size: 3rem;
				padding: 20px;
				margin: 0;
			}
		}
		.our-services-card-main {
			flex-direction: column;
			.our-services-card {
				min-width: unset;
				.our-services-card-icon {
					height: 10rem;
					margin-bottom: 1rem;
				}
			}
		}
	}
	.featured-main {
		padding-top: 20px;
		.shopex-title {
			font-size: 3rem;
			padding-bottom: 1rem;
		}
		.shopex-card-main {
			flex-direction: column;
		}
	}
	.footer-main {
		flex-direction: column-reverse;
		.footer-main-middle {
			margin-top: 20px;
		}
		.contact-area {
			.contact-details {
				text-align: center;
				p {
					width: unset;
				}
			}
		}
	}
}
